<template>
  <div id="app">
    <router-view></router-view>
    <!-- <loading></loading> -->
  </div>
</template>
<script>
// import loading from './assets/css/loader.vue'
export default {
  name: 'App',
  // components: {
  //   loading
  // }
}
</script>

<style>
  @font-face {
  font-family: 'MyFont';
  src: url('@/assets/fonts/CreteRound-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Arial";
  src: url('@/assets/fonts/arial.ttf');
}
@font-face {
  font-family: "Calibri";
  src: url('@/assets/fonts/Calibri.ttf');
}

body {
  font-family: 'MyFont', Calibri , sans-serif;
}
@media print {
  .header, .footer {
    display: none;
  }
}
</style>


import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import VeeValidate from 'vee-validate'
import router from './router/index'
import store from './store'
import Header from '@/components/layout/AppHeader.vue';
import Footer from '@/components/layout/Footer.vue';
import Header2 from '@/components/layout/AppHeader2.vue';
import loader from '@/assets/css/loader.vue';
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'

Vue.use(VueMask);

// Or as a directive-only
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

// Or only as a filter-only
import { VueMaskFilter } from 'v-mask'

Vue.use(VueMeta)
Vue.filter('VMask', VueMaskFilter)
Vue.component('header-component', Header);
Vue.component('header-component2', Header2);
Vue.component('footer-component', Footer);
Vue.component('loader-component', loader);

Vue.use(VeeValidate);
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

import axios from '../../../axios.js';
export default {
    namespace: true,
    state :{
        isLoggedIn:localStorage.getItem('token'),
        userRole:'',
    },
    mutations:{
        IS_USER_LOGGED_IN(state,data){
            state.isLoggedIn=data
        },
        USER_ROLE(state,data){
          state.userRole=data
      }
    },
    actions:{
        async UserLogin({commit},formData){
               const response = await axios.post('api/login',formData)
               localStorage.setItem('token',response.data.token)
               commit('IS_USER_LOGGED_IN',response.data.token)
               return response;
        },
         async UserLogout({commit}){
              const response = await axios.post('api/logout')
              window.localStorage.removeItem('token')
              commit('IS_USER_LOGGED_IN',null)
              return response;
         },
         async UserLogoutStep({commit},formData){
          const response = await axios.post('api/user-logout',formData)
          window.localStorage.removeItem('token');
          commit('IS_USER_LOGGED_IN',null)
          return response;
     },
         async UserRole({commit}){
              const response=await axios.get('api/auth/user-role')
              commit('USER_ROLE',response.data)
         }
        //  async ResetPassword({commit},formData){
        //   const response=await axios.post('api/reset-password',formData)
        //   commit('')
        //   return response
        // },
        // async TokenVerify({commit},formData){
        //   const response=await axios.get(`api/token-verify?token=${formData.token}`)
        //   commit('')
        //   return response
        // },
        // async UpdateResetPassword({commit},formData){
        //   const response=await axios.post('api/update-password',formData)
        //   commit('')
        //   return response
        // }
    },
    getters:{
        user: state => {
            return state.user
          },
          isLoggedIn:state=>{
            return state.isLoggedIn
          },
          UserRole:state=>{
            return state.userRole
          }
    }
}
import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../store/modules/auth/auth.js'
import stamp from '../store/modules/stamp/index';
Vue.use(Vuex)
const store = new Vuex.Store({
    modules: 
    {
        auth,
        stamp
    },
  })
  export default store
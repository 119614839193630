import axios from "axios";

const instance = axios.create({
    // baseURL: 'http://127.0.0.1:8000/',
    baseURL: 'https://api.epunjabservices.com',
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${localStorage.getItem('token')}`
            }
        }
    }
});

export default instance;
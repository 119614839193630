<template>
       <div>
        <loader-component v-if="loading"></loader-component>
        <div class="col-md-8 offset-2 mt-3 form-inline d-flex gap-5">
            <div class="form-group">
                <label for="type">From Date</label>
                <input type="date" class="form-control" name="type" v-model="from_date" />
                <span  v-if="record_not_found" class="mt-4" style="font-size: 1.1rem; color:#FF0000">Record not found!</span>
            </div>
            <div class="form-group">
                <label for="type">To Date</label>
                <input type="date" class="form-control" name="type"  v-model="to_date"/>
            </div>
            <div class="form-group mt-4">
                <input type="submit" class="btn btn-success btn-sm" name="type" value="GET REPORT"  @click="searchStamp()" />
            </div>
          
        </div>

        <div class="col-md-8 offset-2  mt-3" v-if="Object.entries(searchStampData).length > 0">
            <h5 class="login-form-title card-header" style="font-family: 'MyFont', serif;"><i class="fa fa-list"></i>&nbsp;&nbsp;
                Selected Data
            </h5>
                <table class="table table-bordered mt-3" style="font-size: 0.85rem;">
                <thead class="bg-success text-light">
                    <tr>
                        <td>Denomination</td>
                        <td>Serial Number</td>
                        <td>Purpose</td>
                        <td>Person Name</td>
                        <td>Person CNIC</td>
                        <td>Contact</td>
                        <td>Date Time</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="Object.entries(searchStampData).length > 0">
                        <tr v-for="(stamp, index) in searchStampData" :key="index">
                            <td>{{ stamp.denomination }}</td>
                            <td>{{ stamp.serial_no }}</td>
                            <td>{{ stamp.purpose }}</td>
                            <td>{{ stamp.name }}</td>
                            <td>{{ stamp.cnic }}</td>
                            <td>{{ stamp.contact }}</td>
                            <td>{{ stamp.issue_date }}</td>
                            <td>
                                <button class="btn  btn-sm" @click="printing(stamp.serial_no)">
                                    <i class="fa fa-print"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="7">No records available</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
       </div>
</template>
<script>
export default ({
    name: "cnic-filter",
    data() {
        return {
            from_date: '',
            to_date: '',
            isstampSerialNumberFocused: false,
            isstampSerialNumberEmpty: false,
            stampSerialNumber: '',
            searchStampData: '',
            formatDate: '',
            validDate: '',
            loading: false,
            record_not_found:false
        }
    },
    methods: {

        async searchStamp() {
           
            if ((this.from_date.trim() === '') || (this.to_date.trim() === '')) {
                alert('selet date');
            }
            else {
                // this.isstampSerialNumberEmpty = false
                try {
                    this.loading = true;
                    let formData = {
                        from_date: this.from_date,
                        to_date: this.to_date,
                        type: 'date'
                    }
                    const response = await this.$store.dispatch('reportStamp', formData)
                    this.searchStampData = response.data.data;
                    this.loading = false;
                    this.record_not_found=false;
                    // const momentDate = moment(this.searchStampData.issue_date);
                    // this.formatDate = momentDate.format('DD-MMM-YYYY hh:mm:ssA');
                    // const validityDate = moment(this.searchStampData.issue_date).add(7, 'days');
                    // this.validDate = validityDate.format('DD-MMM-YYYY');
                    // this.loading = false;
                } catch (e) {
                    this.loading = false
                    this.foruthStep2 = false
                    this.validMsg = false
                    this.record_not_found=true;
                    this.searchStampData = '';
                }

            }
        },
        printing(serial_no) {
            this.$router.push({name:'StampPrint', params:{id: serial_no}});
        },
    }
})
</script>
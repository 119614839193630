<template>
    <div class="row mt-5">
        <div class="col-md-8 offset-2 card-header">
            <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i class="fa fa-globe"></i>Stamp Issuance
                Report</h5>
        </div>
        <div class="col-md-3 offset-2 mt-3">
            <div class="form-group mb-2">
                <label>
                <input class="" type="radio" name="filterType" value="dateFilter" v-model="filterType" />&nbsp;Apply Date
                Filters</label>
            </div>
            <div class="form-group mb-2">
                <label>
                <input class="" type="radio" name="filterType" value="cnicFilter" v-model="filterType" />&nbsp;Search With
                CNIC</label>
            </div>
            <div class="form-group mb-2 ">
                <label>
                <input class="" type="radio" name="filterType" value="serialFilter" v-model="filterType" />&nbsp;Search With
                Serial</label>
            </div>
        </div>
        <div class="col-md-3"></div>
        <dateFilter v-if="filterType === 'dateFilter'"></dateFilter>
        <cnicFilter v-if="filterType === 'cnicFilter'"></cnicFilter>
        <serialFilter v-if="filterType === 'serialFilter'"></serialFilter>

    </div>
</template>
<script>
import dateFilter from '../reports/filters/dateFilter.vue';
import cnicFilter from '../reports/filters/cnicFilter.vue';
import serialFilter from '../reports/filters/serialFilter.vue';
export default ({
    name: 'issuanc-Report',
    components: {
        dateFilter,
        cnicFilter,
        serialFilter
    },
    data() {
        return {
            filterType: 'dateFilter',
        }
    }
});
</script>
<template>
  <div class="header">
    <loader-component v-if="loading"></loader-component>
    <div class="container-fluid" style="background-color: #D7F4E1; height:30px;">
      <div class="col-md-12">
        <span style="font-size: 13px; padding: 9px; font-family:Arial, sans-serif !important; line-height: 30px;"><i class="fa fa-home"
            style="background-color: #CCCCCC;padding: 4px; color:#3480b3;border-radius: 30%; line-height: 15px;"></i>
          <i class="fa fa-phone" style="transform: rotate(90deg); padding: 3px;"></i>HELP LINE: 08000-8100
        </span>
      </div>
    </div>
    <div class="container-fluid" style="background-color:#38922E;">
      <nav class="navbar justify-content-between">
        <a class="navbar-brand">
          <router-link class="nav-link" to="/stampVendorDashboard">
            <img src="/img/logo.png" height="80px">
          </router-link>
          
        </a>
        <ul class="navbar-nav  " style="line-height: 8px; margin: 0 0.8rem 0 0 !important;" v-if="checkLogin">
          <li class="nav-item">
            <a class="nav-link active text-light" href="#">Hello Mst Zeenat Ali</a>
          </li>
          <li class="nav-item">
            <span class="nav-link text-light">Change Password</span>
            <span class="nav-link text-light">User Activity log</span>
          </li>
          <li class="nav-item">
            <span class="nav-link text-light" style="cursor:pointer" @click="UserLogout()">Log Off</span>
          </li>
        </ul>
        <ul class="navbar-nav" style="line-height: 3px; margin: 0 0 4rem 0 !important;" v-else>
          <li class="nav-item">
            <router-link class="nav-link text-white" to="/stampVendorlogin" style="font-weight:normal;">Login</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading :false
    }
  },
  methods: {
    async UserLogout() {
      try {
        this.loading = true;
        await this.$store.dispatch('UserLogout')
        this.loading = false;
        this.$router.push('/stampVendorLogin');
         
      }
      catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
  computed: {
    checkLogin() {
      return this.$store.getters.isLoggedIn
    }
  }
}
</script>
<style scoped>
h1 {
  font-family: Calibri, sans-serif;
}
</style>
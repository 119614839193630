<template>
    <div>
    <header-component></header-component>
    <loader-component v-if="loading"></loader-component>
      <div class="container-fluid" style="background-color:#E7E7E7">.</div>
        <div class="container mx-auto mb-5" >
          <h4 class="text-center mt-5 mb-2" style="font-family: 'MyFont', serif;">Verify/Re-print Issued Low Denomination Stamp</h4>
          <div class="card  border-0">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-9">
                  <div class="card-header border-0">
                    <h5 class="login-form-title" style="font-family: 'MyFont', serif;"><i class="fa fa-search" style="padding: 6px;"></i>Search Stamp Serial</h5>
                  </div>
                </div>
              </div>
            <div class="card-body">
            <div class="row d-flex justify-content-center align-items-center">
                  <div class="col-md-4">
                      <div class="form-group">
                          <label class="intial-label" for="stampSerialNumber"  v-bind:class="{ 'lable_active': isstampSerialNumberFocused,'text-danger':isstampSerialNumberEmpty}">Enter Stamp Serial Number</label>
                          <input type="text" id="stampSerialNumber" autocomplete="off" class="form-control border-0 border-bottom rounded-0" v-model="stampSerialNumber"  
                          v-bind:class="{ 'border-success': isstampSerialNumberFocused, 'border-danger':isstampSerialNumberEmpty}" @focus="stampSerialNumberClick()" v-on:blur="stampSerialNumberClickout()">
                          <span v-if= "isstampSerialNumberEmpty" style="font-size: 12px; color:#FF0000">Stamp Serial Number is required</span>
                          <span v-if="record_not_found" style="font-size: 12px; color:#FF0000">Record not found!</span>
                      </div>
                  </div>
                  <div class="col-md-4 mt-3">
                      <div class="form-group">
                          <button class="btn btn-success" @click="searchStamp()"> <i class="fa fa-search"></i>Search</button>
                         
                      </div>
                  </div>
            </div>
            <div class="row mt-3" v-if="validMsg">
              <div class="col-md-10">
                <p style="font-weight:bold;color:red;text-align:center">
                  Transaction is valid for one week from its Date of Issuance. ({{ formatDate }})
                </p>
              </div>
            </div>
            </div>
         </div>
         
         <div v-if="foruthStep2">
          <div class="card  border-0">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-9">
                  <div class="card-header border-0">
                    <h5 class="login-form-title" style="font-family: 'Calibri', serif;"><i class="fa fa-users"></i>&nbsp;&nbsp;Total Payable Amount</h5>
                  </div>
                </div>
              </div>
            <div class="card-body">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-4">
                  <p>
                    <b>Total Payable Amount (Rs.):</b> <br>
                    {{ searchStampData.denomination }}
                  </p>
                </div>
                <div class="col-md-4">
                  <p>
                    <b>Stamp Status:</b> <br>
                    Stamp Issued
                  </p>
                </div>
            </div>
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-md-4">
                      <p>
                        <b>Issue Date:</b> <br>
                        {{ formatDate }}
                      </p>
                    </div>
                    <div class="col-md-4">
                      <p>
                        <b>Stamp Delisted On / Validity:</b> <br>
                        {{validDate}}
                      </p>
                    </div>
                </div>
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-md-9">
                <table class="table table-bordered">
                    <thead>
                      <tr class="card-header">
                        <th>Sr.#</th>
                        <th>Serial Number</th>
                        <th>Denomination</th>
                        <th>Purpose</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{{ searchStampData.serial_no }}</td>
                        <td>{{ searchStampData.denomination }}</td>
                        <td>{{ searchStampData.purpose }}</td>
                        <td>{{ searchStampData.reason }}</td>
                      </tr>
                    </tbody>
                </table>
            </div>
            </div>
            
            </div>
         </div> 
         <div class="card  border-0">
              <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-9">
                  <div class="card-header border-0">
                    <h5 class="login-form-title" style="font-family: 'Calibri', serif;" ><i class="fa fa-user"></i>&nbsp;&nbsp;Applicant Information</h5>
                  </div>
                </div>
              </div>
            <div class="card-body">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-9">
                <table class="table table-bordered">
                    <thead>
                      <tr class="card-header">
                        <th>Name</th>
                        <th>CNIC</th>
                        <th>Address</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ searchStampData.name }} {{ searchStampData.relation }} {{ searchStampData.relation_name }}</td>
                        <td>{{ searchStampData.cnic }}</td>
                        <td>{{searchStampData.address}}</td>
                        <td></td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-9">
                <button class="btn btn-success float-end text-uppercase" @click="printing()">print application</button>
              </div>
            </div>
            </div>
         </div>
         </div>

        </div>
        <footer-component></footer-component>
    </div>
</template>
<script>
import moment from 'moment';
export default{
  metaInfo: {
  title: "Search Stamp"
  },
    name:"Vendor-Dashboard",
    data (){
        return {
            foruthStep2:false,
            validMsg:false,
            isstampSerialNumberFocused: false,
            isstampSerialNumberEmpty: false,
            stampSerialNumber: '',
            searchStampData: {},
            formatDate:'',
            validDate:'',
            loading: false,
            record_not_found: false,
        }
    },
    methods: {
        stampSerialNumberClick(){
          this.isstampSerialNumberFocused = true
          this.record_not_found = false;
        },
        stampSerialNumberClickout(){
          if(this.stampSerialNumber.trim() === ''){
            this.isstampSerialNumberFocused  = false
            this.isstampSerialNumberEmpty = true
          }
          else {
            this.isstampSerialNumberEmpty = false
          }
        },
      async  searchStamp() {
          if(this.stampSerialNumber.trim() === ''){
            this.isstampSerialNumberFocused  = false
            this.isstampSerialNumberEmpty = true
          }
          else {
            this.isstampSerialNumberEmpty = false
            try {
              this.loading = true;
                let formData = {
                serial_no:this.stampSerialNumber
            }
            const response = await this.$store.dispatch('SearchStamp',formData)
            this.foruthStep2 = true
            this.validMsg =true
            this.searchStampData = response.data.data;
            const momentDate = moment(this.searchStampData.issue_date);
            this.formatDate = momentDate.format('DD-MMM-YYYY hh:mm:ssA');
            const validityDate = moment(this.searchStampData.issue_date).add(7, 'days');
            this.validDate = validityDate.format('DD-MMM-YYYY');
            this.loading = false;
            }catch(e) {
              this.loading = false
                this.foruthStep2 = false
                this.validMsg =false
                this.record_not_found = true
            }
       
          }
        },
        printing() {
            this.$router.push({name:'StampPrint', params:{id: this.searchStampData.serial_no}});
        },
        async UserLogout(){
            try{
                await this.$store.dispatch('UserLogout')
                this.$router.push('/');
            }
            catch(e){
                console.log(e)
            } 
        },
       
    },
    computed: {
        checkLogin() {
        return this.$store.getters.isLoggedIn
      }
    }
 
  
}
</script>
<style scoped>
.intial-label {
    font-size: 1rem;
    transform: translateY(1.8rem);
}
.lable_active {
    transform: translateY(-0.01rem);
    transition: all 0.3s ease;
    color:#28a745;
}
  .form-control:focus {
  outline: none;
  box-shadow: none;
}
.border-success {
  border-color: #28a745;
  transition: border-color .3s ease-in-out;
}
label {
  color: #aaa;
}

</style>
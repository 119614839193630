<template>
    <div style="background-color: rgb(246, 246, 246);">
      <header-component2></header-component2>
  <div class="mb-5">
    <loader-component v-if="loading"></loader-component>
    <div class="hideable">
      <div class="container-fluid" style="background-color:#E7E7E7">
        <router-link class="nav-link" to="#">Home</router-link>
      </div>
    </div>
    <div class="container" style="background-color: rgb(246, 246, 246);">
    <div id="printable-content">
      <h4 style="font-size:20px; margin-bottom:10px; margin-top: 80px; margin-left: 5%;">eStamp Online Verification</h4>
      <center>
      <table style="max-width:100%; width: 90%;">
      <tr>
        <th>eStamp ID</th>
        <td>{{ searchStampData.serial_no }}</td>
      </tr>
      <tr>
        <th>Type</th>
        <td> Low Denomination</td>
      </tr>
      <tr>
        <th>Amount</th>
        <td>{{ searchStampData.denomination }}</td>
      </tr>
      <tr>
        <th>Description</th>
        <td>{{ searchStampData.purpose }}</td>
      </tr>
      <tr>
        <th>Applicant</th>
        <td>{{ searchStampData.name }} [{{ searchStampData.cnic }}]</td>
      </tr>
      <tr>
        <th>{{searchStampData.relation}}</th>
        <td>{{ searchStampData.relation_name }}</td>
      </tr>
      <tr>
        <th>Address</th>
        <td>{{ searchStampData.address }}</td>
      </tr>
      <tr>
        <th>Issue Date</th>
        <td>{{ intialDate }}</td>
      </tr>
      <tr>
        <th>Delisted on/Validity</th>
        <td>{{ validDate }}</td>
      </tr>
      <tr>
        <th>Amount in Words</th>
        <td>{{ amount_in_words }}</td>
      </tr>
      <tr>
        <th>Reason</th>
        <td>{{ searchStampData.reason }}</td>
      </tr>
      <tr>
        <th>Vendor Information</th>
        <td>Mst Zeenat Ali | PB-RYK-1430 | Ahata Katchehri LQP</td>
      </tr>
    </table>
    </center>
    </div>
  </div>
  </div>
  <footer-component></footer-component>
    </div>
</template>
<script>
import moment from 'moment';
// import VueBarcode from 'vue-barcode';
// import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: "Vendor-Dashboard",
  data() {
    return {
      loading: false,
      uuid: this.$route.params.id,
      // firstStep: true,
      intialDate: '',
      validDate: '',
      searchStampData: {},
    }
  },
  metaInfo: {
  title: "Government of the Punjab - eStamping Citizen Portal"
  },
  
  methods: {
    async searchStamp() {
      this.loading = true
      if (this.uuid === '') {
        // alert()
      }
      else {
        try {
          let formData = {
            serial_no: this.uuid
          }
          const response = await this.$store.dispatch('VerifyStamp', formData)
          this.searchStampData = response.data.data;
          const momentDate = moment(this.searchStampData.issue_date);
          this.intialDate = momentDate.format('DD-MMM-YYYY hh:mm:ssA');
          const validityDate = moment(this.searchStampData.issue_date).add(7, 'days');
          this.validDate = validityDate.format('DD-MMM-YYYY');
          this.loading = false
          if (this.searchStampData.denomination == 100) {
            this.amount_in_words = "One Hundred Rupees Only"
          } else if (this.searchStampData.denomination == 200) {
            this.amount_in_words = "Two Hundred Rupees Only"
          } else {
            this.amount_in_words = "Three Hundred Rupees Only"
          }
          // alert()
          
        } catch (e) {
          console.log(e)
          this.loading = false
        }

      }
    },
    printContent() {

      let header = document.querySelector('.header');
      let footer = document.querySelector('.footer');
      // Hide the header and footer
      header.style.display = 'none';
      footer.style.display = 'none';
      // Print the content
      window.print();

      // Show the header and footer again
      header.style.display = '';
      footer.style.display = '';
    },
    
    // async UserLogout(){
    //         try{
    //             await this.$store.dispatch('UserLogout')
    //             this.$router.push('/');
    //         }
    //         catch(e){
    //             console.log(e)
    //         } 
    //     },
  },
  created() {
    this.searchStamp()
  },
  // computed: {
  //       checkLogin() {
  //       return !this.$store.getters.isLoggedIn
  //     }
  //   }

}
</script>
<style scoped>
.intial-label {
  font-size: 1rem;
  transform: translateY(1.8rem);
}

.lable_active {
  transform: translateY(-0.01rem);
  transition: all 0.3s ease;
  color: #28a745;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.border-success {
  border-color: #28a745;
  transition: border-color .3s ease-in-out;
}

label {
  color: #aaa;
}

@media print {
  @page {
    size: auto;
    /* Set the paper size to auto */
    margin: 0mm;
    /* Remove default margins */
  }

  /* Hide headers and footers */
  ::-webkit-print-header,
  ::-webkit-print-footer,
  ::-moz-print-header,
  ::-moz-print-footer,
  ::print-header,
  ::print-footer {
    display: none;
  }

  .hideable {
    display: none;
  }

  #view-content {
    display: none;
  }

  #printable-content {
    display: block !important;
    font-family: Arial, sans-serif;

  }

}
#view-content {
  font-family: Arial, sans-serif;
}
/* #printable-content {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
} */
table, th, td {
  font-family: RobotoDraft,Roboto,Helvetica, Neue,Helvetica,Arial,sans-serif;
  font-size: 14px;
  border: 1px solid;
  border-color: #ddd;
  font-weight: 300;
}
td, th {
  word-wrap: normal;
  padding: 8px;
}
th {
  font-weight: bold;
}
tr {
  line-height: 1.428571429;
}
</style>
<template>
    <div>
        <loader-component v-if="loading"></loader-component>
        <div class="col-md-6 offset-2 mt-3 form-inline d-flex gap-5">
            <div class="col-md-4">
                <div class="form-group">
                    <label class="intial-label" for="stampSerialNumber"
                        v-bind:class="{ 'lable_active': isstampSerialNumberFocused, 'text-danger': isstampSerialNumberEmpty }">Enter
                        CNIC</label>
                    <input type="text" autocomplete="off" id="stampSerialNumber" class="form-control border-0 border-bottom rounded-0"
                        v-model="stampSerialNumber"
                        v-bind:class="{ 'border-success': isstampSerialNumberFocused, 'border-danger': isstampSerialNumberEmpty }"
                        @focus="stampSerialNumberClick()" v-on:blur="stampSerialNumberClickout()">
                    <span v-if="isstampSerialNumberEmpty" style="font-size: 12px; color:#FF0000">CNIC is required</span>
                    <span v-if="record_not_found" style="font-size: 1.1rem; color:#FF0000">Record not found!</span>

                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="form-group">
                    <button class="btn btn-success btn-sm text-uppercase" @click="searchStamp()">get report</button>
                </div>
            </div>
        </div>
        <div class="col-md-8 offset-2  mt-5" v-if="Object.entries(searchStampData).length > 0">
            <h5 class="login-form-title card-header" style="font-family: 'MyFont', serif;"><i class="fa fa-list"></i>&nbsp;&nbsp;
                Selected Data
            </h5>
            <table class="table table-bordered mt-3" style="font-size: 0.85rem;">
                <thead class="bg-success text-light">
                    <tr>
                        <td>Denomination</td>
                        <td>Serial Number</td>
                        <td>Purpose</td>
                        <td>Person Name</td>
                        <td>Person CNIC</td>
                        <td>Contact</td>
                        <td>Date Time</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="Object.entries(searchStampData).length > 0">
                        <tr v-for="(stamp, index) in searchStampData" :key="index">
                            <td>{{ stamp.denomination }}</td>
                            <td>{{ stamp.serial_no }}</td>
                            <td>{{ stamp.purpose }}</td>
                            <td>{{ stamp.name }}</td>
                            <td>{{ stamp.cnic }}</td>
                            <td>{{ stamp.contact }}</td>
                            <td>{{ stamp.issue_date }}</td>
                            <td>
                                <button class="btn  btn-sm" @click="printing(stamp.serial_no)">
                                    <i class="fa fa-print"></i>
                                </button>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="7">No records available</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default ({
    name: "cnic-filter",
    data() {
        return {
            isstampSerialNumberFocused: false,
            isstampSerialNumberEmpty: false,
            stampSerialNumber: '',
            searchStampData: '',
            formatDate: '',
            validDate: '',
            loading: false,
            record_not_found:false
        }
    },
    methods: {
        stampSerialNumberClick() {
            this.isstampSerialNumberFocused = true
            this.record_not_found = false;
        },
        stampSerialNumberClickout() {
            if (this.stampSerialNumber.trim() === '') {
                this.isstampSerialNumberFocused = false
                this.isstampSerialNumberEmpty = true
                this.record_not_found = false;
            }
            else {
                this.isstampSerialNumberEmpty = false
            }
        },
        printing(serial_no) {
            this.$router.push({name:'StampPrint', params:{id: serial_no}});
        },
        async searchStamp() {
            if (this.stampSerialNumber.trim() === '') {
                this.isstampSerialNumberFocused = false
                this.isstampSerialNumberEmpty = true
            }
            else {
                this.isstampSerialNumberEmpty = false
                try {
                    this.loading = true;
                    let formData = {
                        cnic: this.stampSerialNumber,
                        type: 'cnic'
                    }
                    const response = await this.$store.dispatch('reportStamp', formData)
                   
                    if(response.status==200) {
                        this.record_not_found=false
                        this.searchStampData = response.data.data;
                        this.loading = false;
                    }

                    // const momentDate = moment(this.searchStampData.issue_date);
                    // this.formatDate = momentDate.format('DD-MMM-YYYY hh:mm:ssA');
                    // const validityDate = moment(this.searchStampData.issue_date).add(7, 'days');
                    // this.validDate = validityDate.format('DD-MMM-YYYY');
                    // this.loading = false;
                } catch (e) {
                    this.searchStampData = '';
                    this.record_not_found = true;
                    this.loading = false;
                }

            }
        },
    }
})
</script>
<style scoped>
.intial-label {
    font-size: 1rem;
    transform: translateY(1.8rem);
}

.lable_active {
    transform: translateY(-0.01rem);
    transition: all 0.3s ease;
    color: #28a745;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.border-success {
    border-color: #28a745;
    transition: border-color .3s ease-in-out;
}

label {
    color: #aaa;
}
</style>
<template>
  <div class="loader">
      <div class="loader-circle">
        <div class="loader-circle2"></div>
      </div>
  </div>
</template>
<script>
export default {
  name: "loader-vue",
}
</script>
<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
}

.loader:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      background:rgba(255, 255, 255, 0.4);
  
    /* background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8)); */
  }
   /* :not(:required) hides these rules from IE9 and below */
   .loader:not(:required) {
    /* hide "loader..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loader:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
  }
  
.loader-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: #81C784;
  border-bottom-color: #81C784;
  border-right-color: transparent;
  border-left-color: transparent;
  animation: spin 1.1s infinite linear;
}
.loader-circle2 {
  position: absolute;
  top: 20%;
  left: 20%;
  transform: translate(-20%, -20%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 7px solid transparent;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: #81C784;
  border-left-color: #81C784;
  animation: reverses 0.6s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);

 }
}

@keyframes reverses {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);

 }
}
</style>
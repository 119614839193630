<template>
    <div>
        <header-component></header-component>
        <loader-component v-if="loading"></loader-component>
        <div class="container-fluid" style="background-color:#E7E7E7">.</div>
        <div class="container mx-auto mb-5" style="font-family:Calibri, sans-serif !important;">
            <h3 class="text-center mt-5 mb-2" style="font-family: 'Calibri', serif;">View Reports</h3>
            <div class="card  border-0">
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-md-10">
                        <div class="card-header border-0">
                            <h5 class="login-form-title" style="font-family: 'Calibri', serif;"><i
                                    class="fa fa-search"></i>View Reports</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 offset-1 mt-3">
                        <div class="form-group mb-2">
                            <label class="text-dark">
                                <input class="" type="radio" name="viewReportType" value="stockReports"
                                    v-model="viewReportType" />&nbsp;Stock Reports
                            </label>
                        </div>
                        <div class="form-group ">
                            <label class="text-dark">
                                <input class="" type="radio" name="viewReportType" value="stampIssuanceReport"
                                    v-model="viewReportType" />&nbsp;Stamp Issuance Report
                            </label>
                        </div>
                    </div>
                </div>
                <StampIssuanceReport v-if="viewReportType === 'stampIssuanceReport'"></StampIssuanceReport>
            </div>
        </div>

        <footer-component></footer-component>
    </div>
</template>
<script>
import moment from 'moment';
import StampIssuanceReport from './reports/StampIssuanceReport.vue';

export default {
    metaInfo: {
    title: "Reports"
  },
    name: "Vendor-Dashboard",
    components: {
        StampIssuanceReport
    },
    data() {
        return {
            viewReportType: 'stockReports',
            validMsg: false,
            isstampSerialNumberFocused: false,
            isstampSerialNumberEmpty: false,
            stampSerialNumber: '',
            searchStampData: {},
            formatDate: '',
            validDate: '',
            loading: false
        }
    },
    methods: {
        stampSerialNumberClick() {
            this.isstampSerialNumberFocused = true
        },
        stampSerialNumberClickout() {
            if (this.stampSerialNumber.trim() === '') {
                this.isstampSerialNumberFocused = false
                this.isstampSerialNumberEmpty = true
            }
            else {
                this.isstampSerialNumberEmpty = false
            }
        },
        async searchStamp() {
            if (this.stampSerialNumber.trim() === '') {
                this.isstampSerialNumberFocused = false
                this.isstampSerialNumberEmpty = true
            }
            else {
                this.isstampSerialNumberEmpty = false
                try {
                    this.loading = true;
                    let formData = {
                        serial_no: this.stampSerialNumber
                    }
                    const response = await this.$store.dispatch('SearchStamp', formData)
                    this.foruthStep2 = true
                    this.validMsg = true
                    this.searchStampData = response.data.data;
                    const momentDate = moment(this.searchStampData.issue_date);
                    this.formatDate = momentDate.format('DD-MMM-YYYY hh:mm:ssA');
                    const validityDate = moment(this.searchStampData.issue_date).add(7, 'days');
                    this.validDate = validityDate.format('DD-MMM-YYYY');
                    this.loading = false;
                } catch (e) {
                    this.loading = false
                    this.foruthStep2 = false
                    this.validMsg = false
                }

            }
        },
        printing() {
            this.$router.push({ name: 'StampPrint', params: { id: this.searchStampData.serial_no } });
        },
        async UserLogout() {
            try {
                await this.$store.dispatch('UserLogout')
                this.$router.push('/');
            }
            catch (e) {
                console.log(e)
            }
        },

    },
    computed: {
        checkLogin() {
            return this.$store.getters.isLoggedIn
        }
    }


}
</script>
<style scoped>
.intial-label {
    font-size: 1rem;
    transform: translateY(1.8rem);
}

.lable_active {
    transform: translateY(-0.01rem);
    transition: all 0.3s ease;
    color: #28a745;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.border-success {
    border-color: #28a745;
    transition: border-color .3s ease-in-out;
}

label {
    color: #aaa;
}</style>
<template>
    <div>
      <header-component></header-component>
      <loader-component v-if="loading"></loader-component>
      <div class="mb-5">
        <loader-component v-if="loading"></loader-component>
          <div class="hideable">
            <div class="container-fluid" style="background-color:#E7E7E7">
              <router-link class="nav-link" to="/stampVendorDashboard">Home</router-link>
            </div>
            <h3 @click="printContent()" style="cursor: pointer; float: right; margin: 20px auto ;"><i class="fa fa-print"></i>
            </h3>
          </div>
          <div id="view-content">
            <center>
            <table style="max-width:90%;  margin: 50px 0 0 1px;">
            <tr>
              <td colspan="3" align="center">
                <h2 style="font-weight:bold; font-weight: 700;">E-STAMP</h2>
              </td>
            </tr>
            <tr>
              <td>
                  <barcode :value="searchStampData.serial_no" displayValue="false" width="1" fontSize=2  height="35"
                  format="CODE128"></barcode>
              </td>
            </tr>
            <tr>
              <td>ID :</td>
              <td><strong>{{ searchStampData.serial_no }}</strong></td>
              <td rowspan="4" align="center">
                <qr-code :text="qrcode_data" error-level="H" size="88"  style="max-width: 100px;">code</qr-code>
              </td>
            </tr>
            <tr>
              <td >Type :</td>
              <td ><strong>Low Denomination</strong></td>
            </tr>
            <tr>
              <td >Amount :</td>
              <td ><strong>Rs {{ searchStampData.denomination }}/-</strong></td>
            </tr>
            <tr>
              <td align="right" colspan="2">
                <span style="font-size: 12px; line-height: 30px;">Scan for online verification</span>
              </td>
            </tr>
            <tr>
              <td>Description :</td>
              <td>{{ searchStampData.purpose }}</td>
            </tr>
            <tr>
              <td>Applicant :</td>
              <td>{{ searchStampData.name }}[{{ searchStampData.cnic }}]</td>
            </tr>
            <tr>
              <td>{{ searchStampData.relation }} :</td>
              <td>{{ searchStampData.relation_name }}</td>
            </tr>
            <tr v-if="searchStampData.through == 'Self'">
              <td>Agent :</td>
              <td>{{ searchStampData.through }}</td>
            </tr>
            <tr v-else>
              <td>Agent :</td>
              <td>{{ searchStampData.agent_name }} [{{ searchStampData.agent_cnic }}]</td>
            </tr>
            <tr>
              <td>Address :</td>
              <td>{{ searchStampData.address }}</td>
            </tr>
            <tr>
              <td>Issue Date :</td>
              <td>{{ intialDate }}</td>
            </tr>
            <tr>
              <td>Delisted On / Validity :</td>
              <td>{{ validDate }}</td>
            </tr>
            <tr>
              <td>Amount in words :</td>
              <td>{{ amount_in_words }}</td>
              <!-- <td v-if="searchStampData.denomination == 100">
                One Hundred Rupees Only
              </td>
              <td v-if="searchStampData.denomination == 200">
                Two Hundred Rupees Only
              </td>
              <td v-if="searchStampData.denomination == 300">
                Three Hundred Rupees Only
              </td> -->
            </tr>
            <tr>
              <td>Reason :</td>
              <td>{{ searchStampData.reason }}</td>
            </tr>
            <tr>
              <td>Vendor Information :</td>
              <td>
                Mst Zeenat Ali | PB-RYK-1430 | Ahata Katchehri LQP
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <br>
                <!-- <img  src="/img/urdu3.png" style="max-width: 95%; margin: 0 auto;"/> -->
                <p style="direction: rtl; border: 2px solid black; padding: 15px; font-weight: 700; font-size: 13px;  margin: 1px 0 0;">
                  نوٹ: یہ ٹرانذیکشن تاریخ اجرا سے سات دنوں تک کے لیے قابل استعمال ہے۔ای اسٹامپ کی تصدیق بذریعہ ویب سائٹ،کیوآر کوڈ یا ایس ایم ایس سے کی جاسکتی ہے۔
                <br>
                <span style="font-size:13px; letter-spacing:4px;">Type "eStamp &lt;16 digit eStamp Number&gt;" send to 8100</span>
                </p>
              </td>
            </tr>
          </table>
          </center>
        </div>

        <!-----Printable content-->
        <div id="printable-content" style="display: none;" >
          <center>
          <table style="max-width:70%;  margin: 90px 150px 90px 90px; font-family:Calibri, sans-serif; font-size: 90%;">
          <tr>
            <td colspan="3" align="center">
              <h1 style="font-weight:bold; font-size:28px; letter-spacing: 1px; font-weight: 800; font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; text-decoration: underline;">E-STAMP</h1>
            </td>
          </tr>
          <tr>
            <td style="max-width: 70px;">
              <div style="width: 220px; overflow: hidden;">
                <barcode :value="searchStampData.serial_no" displayValue="false" width="1" fontSize=10  height="30"
                format="CODE128"></barcode>
              </div>
            </td>
            <td></td>
            <td rowspan="5" align="center" >
              <qr-code :text="qrcode_data" error-level="H" size="94" style="margin-top: 20px;"></qr-code>
              <span style="font-size: 14px; width: fit-content;">Scan for online verification</span>
            </td>
          </tr>
          <tr style="line-height: 12px; ">
            <td width="180px">&nbsp;&nbsp;ID :</td>
            <td><strong>{{ searchStampData.serial_no }}</strong></td>
          </tr>
          <tr style="line-height: 14px;">
            <td>&nbsp;&nbsp;Type :</td>
            <td><strong>Low Denomination</strong></td>
          </tr>
          <tr style="line-height: 16px;">
            <td>&nbsp;&nbsp;Amount :</td>
            <td><strong>Rs {{ searchStampData.denomination }}/-</strong></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;Description :</td>
            <td>{{ searchStampData.purpose }}</td>
          </tr>
          <tr style="line-height: 11px;">
            <td>&nbsp;&nbsp;Applicant :</td>
            <td>{{ searchStampData.name }}[{{ searchStampData.cnic }}]</td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;{{ searchStampData.relation }} :</td>
            <td>{{ searchStampData.relation_name }}</td>
          </tr>
          <tr v-if="searchStampData.through == 'Self'" style="line-height: 12px;">
            <td>&nbsp;&nbsp;Agent :</td>
            <td>{{ searchStampData.through }}</td>
          </tr>
          <tr v-else>
            <td>&nbsp;&nbsp;Agent :</td>
            <td>{{ searchStampData.agent_name }} [{{ searchStampData.agent_cnic }}]</td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;Address :</td>
            <td>{{ searchStampData.address }}</td>
          </tr>
          <tr style="line-height: 12px;">
            <td>&nbsp;&nbsp;Issue Date :</td>
            <td>{{ intialDate }}</td>
          </tr>
          <tr>
            <td>&nbsp;&nbsp;Delisted On / Validity :</td>
            <td>{{ validDate }}</td>
          </tr>
          <tr style="line-height: 14px;">
            <td>&nbsp;&nbsp;Amount in words :</td>
            <td>{{ amount_in_words }}</td>
            <!-- <td v-if="searchStampData.denomination == 100">
              {{amount_in_words}}
            </td>
            <td v-if="searchStampData.denomination == 200">
              {{amount_in_words}}
            </td>
            <td v-if="searchStampData.denomination == 300">
              Three Hundred Rupees Only
            </td> -->
          </tr>
          <tr>
            <td>&nbsp;&nbsp;Reason :</td>
            <td>{{ searchStampData.reason }}</td>
          </tr>
          <tr style="line-height: 11px;">
            <td>&nbsp;&nbsp;Vendor Information :</td>
            <td>
              Mst Zeenat Ali | PB-RYK-1430 | Ahata Katchehri LQP
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <br>
              <!-- <img  src="/img/urdu3.png" style="max-width: 95%; margin: 0 auto;"/> -->
              <p style="width:610px;direction: rtl; border: 3px solid black; padding: 10px; font-weight: 700; font-size: 11px;  margin: 1px 7px 0 10px;">
                نوٹ: یہ ٹرانذیکشن تاریخ اجرا سے سات دنوں تک کے لیے قابل استعمال ہے۔ای اسٹامپ کی تصدیق بذریعہ ویب سائٹ،کیوآر کوڈ یا ایس ایم ایس سے کی جاسکتی ہے۔
              <br>
              <span style="font-size:13px; font-family: Arial, Helvetica, sans-serif; font-weight: 300;">Type "eStamp &lt;16 digit eStamp Number&gt;" send to 8100</span>
              </p>
            </td>
          </tr>
        </table>
        </center>
        </div>
    </div>
      <footer-component></footer-component>
  </div>
</template>
<script>
import moment from 'moment';
import VueBarcode from 'vue-barcode';
import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: "Vendor-Dashboard",
  data() {
    return {
      loading: false,
      uuid: this.$route.params.id,
      firstStep: true,
      intialDate: '',
      validDate: '',
      searchStampData: {},
      qrcode_data: 'asdfasd',
    }
  },
  metaInfo: {
  title: "Print"
  },
  components: {
    'barcode': VueBarcode,
    'qr-code': VueQRCodeComponent
  },
  methods: {
    async searchStamp() {
      this.loading = true
      if (this.uuid === '') {
        alert()
      }
      else {
        try {
          let formData = {
            serial_no: this.uuid
          }
          const response = await this.$store.dispatch('SearchStamp', formData)
          this.searchStampData = response.data.data;
          const momentDate = moment(this.searchStampData.issue_date);
          this.intialDate = momentDate.format('DD-MMM-YYYY hh:mm:ssA');
          const validityDate = moment(this.searchStampData.issue_date).add(7, 'days');
          this.validDate = validityDate.format('DD-MMM-YYYY');
          this.loading = false
          if (this.searchStampData.denomination == 100) {
            this.amount_in_words = "One Hundred Rupees Only"
          } else if (this.searchStampData.denomination == 200) {
            this.amount_in_words = "Two Hundred Rupees Only"
          } else {
            this.amount_in_words = "Three Hundred Rupees Only"
          }
          this.qrcode_data = 'https://epunjabservices.com/GeneratePDF/Stamp_Receipt/' + this.searchStampData.serial_no

        } catch (e) {
          console.log(e)
          this.loading = false
        }

      }
    },
    printContent() {

      let header = document.querySelector('.header');
      let footer = document.querySelector('.footer');
      // Hide the header and footer
      header.style.display = 'none';
      footer.style.display = 'none';
      // Print the content
      window.print();

      // Show the header and footer again
      header.style.display = '';
      footer.style.display = '';
    },
    async UserLogout(){
            try{
                await this.$store.dispatch('UserLogout')
                this.$router.push('/');
            }
            catch(e){
                console.log(e)
            } 
        },
  },
  created() {
    this.searchStamp()
  },
  computed: {
        checkLogin() {
        return this.$store.getters.isLoggedIn
      }
    }

}
</script>
<style scoped>
.intial-label {
  font-size: 1rem;
  transform: translateY(1.8rem);
}

.lable_active {
  transform: translateY(-0.01rem);
  transition: all 0.3s ease;
  color: #28a745;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.border-success {
  border-color: #28a745;
  transition: border-color .3s ease-in-out;
}

label {
  color: #aaa;
}

@media print {
  @page {
    size: auto;
    /* Set the paper size to auto */
    margin: 0mm;
    /* Remove default margins */
  }

  /* Hide headers and footers */
  ::-webkit-print-header,
  ::-webkit-print-footer,
  ::-moz-print-header,
  ::-moz-print-footer,
  ::print-header,
  ::print-footer {
    display: none;
  }

  .hideable {
    display: none;
  }

  #view-content {
    display: none;
  }

  #printable-content {
    display: block !important;
  }
}
#view-content {
  font-family: Arial, sans-serif;
}
/* #printable-content {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
} */
</style>
<template>
  <div>
    <div class="container-fluisd footer" style="background-color:#58585B">
      <div class="container text-light">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-3 text-center mt-3">
            <h5 class="text-light">About Us</h5>
            <div class="col-md-12" style="border-bottom: 1px solid #867f7d!important; padding: 10px;">
              <a href="https://es.punjab.gov.pk/eStampVendorPortal/#/aboutUs" class="text-light text-decoration-none">e-Stamping</a>
            </div>
            <div class="col-md-12" style="border-bottom: 1px solid #867f7d!important; padding: 10px;">
              <a href="https://www.punjab.gov.pk/board_of_revenue" class="text-light text-decoration-none">Board Of Revenue</a>
            </div>
          </div>
          <div class="col-md-3 text-center ">
            <h5 class="text-light mb-4">Help</h5>
            <div class="col-md-12 " style="border-bottom: 1px solid #867f7d!important; padding: 1px;">
              <a href="https://es.punjab.gov.pk/eStampVendorPortal/#/faqs" class="text-light text-decoration-none">FAQs</a>
            </div>
            <div class="col-md-12">
              <span>&nbsp;</span>
            </div>
          </div>
          <div class="col-md-3 text-center">
            <h5 class="text-light">Contact Us</h5>
            <div class="col-md-12 " style="border-bottom: 1px solid #867f7d!important; padding: 10px;">
              <span>Email: info.es@punjab.gov.pk</span>
            </div>
            <div class="col-md-12">
              <span>&nbsp;</span>
            </div>
          </div>

        </div>
      </div>

      <div class="container pt-5" style="font-size: 12px;">
        <div class="row d-flex justify-content-center align-items-center pt-4"
          style="border-top: 1px solid #867f7d!important; padding: 10px;">
          <div class="col-md-4">
            <!-- <img src="img/footer.png" class="img-fluid" /> -->
            <img :src="getImageUrl('footer.png')" class="img-fluid">
          </div>
          <p class="text-center text-light" style="font-family: 'Open Sans',sans-serif;">© Copyrights GOP 2023 , All
            Rights Reserved</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer-component",
  data() {
    return {

    }
  },
  methods: {
    getImageUrl(filename) {
      return process.env.VUE_APP_BASE_URL + filename;
    }
  },
  created() {

  },
  computed: {

  }
}
</script>
<style scoped>
h1 {
  font-family: 'MyFont', sans-serif;
}
</style>
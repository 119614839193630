import axios from '../../../axios.js';
export default {
    namespace: true,
    state :{
        // isLoggedIn:localStorage.getItem('token'),
        // userRole:'',
    },
    mutations:{
      //   IS_USER_LOGGED_IN(state,data){
      //       state.isLoggedIn=data
      //   },
      //   USER_ROLE(state,data){
      //     state.userRole=data
      // }
    },
    actions:{
        async SaveStamp({commit},formData){
               const response = await axios.post('api/save_stamp',formData);
               commit('');
               return response
        },
        async SearchStamp({commit},formData){
            const response = await axios.post('api/search_stamp',formData)
            commit('');
            return response
     },
     async VerifyStamp({commit},formData){
        const response = await axios.post('api/verify_stamp',formData)
        commit('');
        return response
     },
     async reportStamp({commit},formData){
        const response = await axios.post('api/report-stamp',formData)
        commit('');
        return response
 },
         async UserLogout({commit}){
              window.localStorage.removeItem('token')
              commit('IS_USER_LOGGED_IN',null)
         },
         async UserRole({commit}){
              const response=await axios.get('api/auth/user-role')
              commit('USER_ROLE',response.data)
         }
        //  async ResetPassword({commit},formData){
        //   const response=await axios.post('api/reset-password',formData)
        //   commit('')
        //   return response
        // },
        // async TokenVerify({commit},formData){
        //   const response=await axios.get(`api/token-verify?token=${formData.token}`)
        //   commit('')
        //   return response
        // },
        // async UpdateResetPassword({commit},formData){
        //   const response=await axios.post('api/update-password',formData)
        //   commit('')
        //   return response
        // }
    },
    getters:{
        // user: state => {
        //     return state.user
        //   },
        //   isLoggedIn:state=>{
        //     return state.isLoggedIn
        //   },
        //   UserRole:state=>{
        //     return state.userRole
        //   }
    }
}
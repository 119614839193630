import Vue from 'vue'
import Router from 'vue-router'
import login from '../components/eStampVendorPortalLogin.vue'
import VendorDashboard from '../components/eStampVendorPortalDashboard.vue'
import VendorForm from '../components/eStampVendorPortalForm1.vue'
import StampSearch from '../components/eStampVendorPortalSearch.vue'
import StampPrint from '../components/eStampVendorPortalPrint.vue'
import welcome from '../components/welcome.vue'
import Reports from '../components/eStampVendorPortalReports.vue';
import Verify from '../components/eStampVendorPortalView.vue';
//import PageError from '../components/layout/PageError.vue'
import store from '../store/index'

Vue.use(store)
Vue.use(Router)
const router=new Router(
    {
        mode:'history',
        routes:
        [
            {
                path:'/stampVendorLogin',
                name:'UserLogin',
                component:login,
                meta: {title: "Login"}
            },
            {
                path:'/stampVendorDashboard',
                name:'Dashboard',
                component:VendorDashboard,
                meta: {  isAuth: true }
            },
            {
                path:'/stampVendorPortal',
                name:'Form',
                component:VendorForm,
                meta: {  isAuth: true }
            },
            {
                path:'/stampVendorSearch',
                name:'Stamp Search',
                component:StampSearch,
                meta: {  isAuth: true }
            },
            {
                path:'/stampVendorReports',
                name:'Stamp Reports',
                component:Reports,
                meta: {  isAuth: true }
            },
            {
                path:'/stampVendorPrint/:id',
                name:'StampPrint',
                component:StampPrint ,
                meta: {  isAuth: true }
            },
            {
                path:'/GeneratePDF/Stamp_Receipt/:id',
                name:'StampVerification',
                component:Verify
            },
            {
                path:'/*',
                name:'Welcome',
                component:welcome
            }
        ]
    }
)

router.beforeEach((to, from, next) => 
{
    let tokenStr = localStorage.getItem('token')
    if(to.meta.isAuth)
    {
        if(tokenStr)
        {
          next()
        }
        else
        {
            next("/") 
        }
    }
    else
    {
        next();
    }
})

export default router

  